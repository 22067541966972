import React from "react";
import { Link } from "gatsby";
import Layout from "components/layout";
import ErrorImage1 from "images/404-text.png";
import ErrorImage2 from "images/404-illustration.png";
import Button from "components/ui/button";
import Seo from "components/seo";

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Seo title="404: Not found" />
      <Layout>
        <div className="mt-20 mb-32">
          <div className="sir-container">
            <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
              <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                <div className="relative">
                  <div className="absolute">
                    <div className="">
                      <h1 className="my-2 text-gray-800 font-bold text-2xl">
                        Ooops! We could’nt find what you were looking for...
                      </h1>
                      <p className="my-2 text-gray-800">
                        Please visit our hompage to get where you need to go.
                      </p>
                      <div className="mt-10">
                        <Link
                          to="/"
                          id="cta_login"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            name="Take me there!"
                            classes="bg-sir-primary w-full lg:w-auto h-12"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img src={ErrorImage1} alt="illustration" />
                  </div>
                </div>
              </div>
              <div>
                <img src={ErrorImage2} alt="illustration" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default NotFoundPage;
